<template>
  <b-modal
      id="block-list-modal-id"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ $t('components.custom.playground.new.step-2.calendar.block-list') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-row>
      <b-col cols="12">
        <d-table
            identifier="block"
            :tablefields="tableFields"
            :items="items"
            :per-page="500"
            :empty-text="'components.custom.playground.new.step-2.calendar.empty-blocks'"
            :totalRows="1"
            :is-busy="false"
            :show-pagination="false"
        />
      </b-col>
    </b-row>
    <b-row
        class="mt-3"
    >
      <b-col cols="1"></b-col>
      <b-col class="pr-0" align="center">
        <d-button
            :text="'general.actions.close'"
            :class="'d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title'"
            @on:button-click="hide"
        />
      </b-col>
      <b-col cols="1"></b-col>
    </b-row>
  </b-modal>
</template>
<script>
import Block from "@/classes/doinsport/Block";

export default {
  data: ()=> ({
    items: [],
  }),
  props: {
    timetable: {
      type: Object,
      default: this,
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display: function (changes) {
      this.loadBlocks();
      this.$bvModal.show('block-list-modal-id');
    }
  },
  computed: {
    alignCellsToLeft () {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields () {
      return [
        { key: "block", label: this.$t("views.playground.steps.step-2.time-zone-list.table.block"), sortable: false },
        { key: "name", label: this.$t("views.playground.steps.step-2.time-zone-list.table.name"), sortable: false },
        { key: "userClientStepBookingDuration", class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.managementStepPosition"), sortable: false},
        { key: "userClubStepBookingDuration", class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.customerStepPosition"), sortable: false},
        { key: "paymentMethods",class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.paymentModes"), sortable: false },
      ];
    }
  },
  methods: {
    loadBlocks() {
      this.items = [];

      if (this.timetable !== null && this.timetable.blocks !== null) {
        const blocks = this.timetable.blocks;

        for (const block of blocks) {
          let newBlock = new Block(block, { row: true });

          Object.assign(newBlock, { block: { startAt: block.startAt, endAt: block.endAt } });

          this.items.push(newBlock);
        }

      }
    },
    hide() {
      this.$bvModal.hide('block-list-modal-id');
    }
  },
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 25px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}

/deep/ .modal {
  padding-top: 88px
}

/deep/ .modal-content {
  border-radius: 8px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
